import { LaborFilters } from "../../interfaces/labor-filters.interface";

export class LoadWorkers {
	public static readonly type = "[Roster] load";
	constructor(
		public skipCount: number,
		public maxResult: number
	) { }
}

export class GetWorkerByUnionId {
	public static readonly type = "[Roster] get worker";
	constructor(
		public query: string,
		public unionId: number,
		public skipCount: number,
		public maxResult: number
	) { }
}

export class RemoveWorker {
	public static readonly type = "[Roster] remove";
	constructor(
		public workerId: number,
		public callback: (error) => void
	) {}
}

export class ResetFilteredItems {
	public static readonly type = "[Roster] reset filtered items";
	constructor() { }
}

export class GetWorkHistory {
	public static readonly type = "[Roster] get work history";
	constructor(
		public skipCount: number,
		public maxResult: number,
		public workerId: number,
		public filters?: any
	) { }
}

export class UpdateLaborFilters {
	static readonly type = "[Roster] update filters";
	constructor(
		public filters: LaborFilters
	) {}
}

export class LoadWorkerStatus {
	public static readonly type = "[Roster] load status";
}

export class ImportCertifications {
  public static readonly type = "[Roster] import certifications";
  constructor(
    public payload: FormData,
    public callbackSuccess: (response?) => void,
    public callbackError: () => void
  ) {}
}

export class ImportDebits {
  public static readonly type = "[Roster] import debits";
  constructor(
    public payload: FormData,
    public callbackSuccess: (response?) => void,
    public callbackError: () => void
  ) {}
}